<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: Reset Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div v-if="loading">
        <div class="loading">
            <div class="effect-1 effects"></div>
            <div class="effect-2 effects"></div>
            <div class="effect-3 effects"></div>
        </div>
    </div>
    <div class="h-screen flex w-full bg-img" v-else>
        <div class="vx-col sm:w-3/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
            <vx-card>
                <div slot="no-body" class="full-page-bg-color">
                    <div class="vx-row">
                        <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
                            <img src="@/assets/images/pages/reset-password.png" alt="login" class="mx-auto">
                        </div>
                        <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center  d-theme-dark-bg">
                            <div class="p-8" v-if="error">
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Error</h4>
                                    <p>{{error}}</p>
                                </div>
                            </div>
                            <div class="p-8" v-else>
                                <div class="vx-card__title mb-8">
                                    <h4 class="mb-4">Resetear contraseña</h4>
                                    <p>Por favor, ingrese su nueva contraseña para {{email}}.</p>
                                </div>

                                <vs-input
                                    data-vv-validate-on="blur"
                                    v-validate="'required|min:6|max:16'"
                                    type="password"
                                    name="password"
                                    icon-no-border
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Contraseña"
                                    v-model="password"
                                    ref="password"
                                    class="w-full" />
                                <span class="text-danger text-sm">{{ errors.first('password') }}</span>

                                <vs-input
                                    data-vv-validate-on="blur"
                                    v-validate="'required|confirmed:password'"
                                    type="password"
                                    name="password"
                                    icon-no-border
                                    icon="icon icon-lock"
                                    icon-pack="feather"
                                    label-placeholder="Repita su contraseña"
                                    v-model="passwordAgain"
                                    class="w-full mt-6" />
                                <span class="text-danger text-sm">{{ errors.first('passwordAgain') }}</span>

                                <div class="flex flex-wrap justify-between flex-col-reverse sm:flex-row mt-8">
                                    <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">Volver a login</vs-button>
                                    <vs-button class="w-full sm:w-auto" :disabled="errors.any()" @click="resetPassword">Resetear</vs-button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            error: null,
            password: '',
            passwordAgain: '',
        }
    },
    async created() {
        try {
            let payload = {
                code: this.$route.query.oobCode
            }
            this.email = await this.$store.dispatch('auth/resetPasswordVerification', payload);
        } catch (e) {
            this.error = e.message;
        } finally {
            this.loading = false;
        }
    },
    methods: {
        async resetPassword() {
            try {
                let payload = {
                    code: this.$route.query.oobCode,
                    password: this.password
                }
                await this.$store.dispatch('auth/resetPasswordConfirmation', payload);
                this.$vs.notify({
                    time: 5000,
                    title: 'Contraseña modificada',
                    text: 'Su nueva contraseña ha sido almacenada.',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'warning'
                });
                this.$router.push('/')
            } catch (e) {
                this.error = e.message;
            }
        }
    }
}
</script>
